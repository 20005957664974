@font-face {
    font-family: "Plus Jakarta Sans";
    src: url("/fonts/PlusJakartaSans-VariableFont_wght.ttf");
    font-weight: 100 900;
    font-style: normal;
    font-display: optional;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    src: url("/fonts/PlusJakartaSans-Italic-VariableFont_wght.ttf");
    font-weight: 100 900;
    font-style: italic;
    font-display: optional;
}

/* @font-face {
    font-family: "Plus Jakarta Sans";
    src: url("/fonts/PlusJakartaSans-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    src: url("/fonts/PlusJakartaSans-BoldItalic.ttf");
    font-weight: 100 900;
    font-style: italic;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    src: url("/fonts/PlusJakartaSans-ExtraBold.ttf");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    src: url("/fonts/PlusJakartaSans-ExtraLight.ttf");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    src: url("/fonts/PlusJakartaSans-ExtraLightItalic.ttf");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    src: url("/fonts/PlusJakartaSans-Light.ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    src: url("/fonts/PlusJakartaSans-LightItalic.ttf");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    src: url("/fonts/PlusJakartaSans-Medium.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    src: url("/fonts/PlusJakartaSans-MediumItalic.ttf");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    src: url("/fonts/PlusJakartaSans-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    src: url("/fonts/PlusJakartaSans-SemiBoldItalic.ttf");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    src: url("/fonts/PlusJakartaSans-Regular.ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Plus Jakarta Sans";
    src: url("/fonts/PlusJakartaSans-Italic.ttf");
    font-weight: 500;
    font-style: italic;
} */
